

/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />
/// <reference path="../../services/generic.services.ts" />

class systemsCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService'];
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory
    ) {
        super($scope, $location, $compile, socketService);
        this.collection = 'assets';
        this.basequery = '_type eq \'system\'';
        this.socketService.onSignedin(() => {
            this.loaddata();
        });
    }

}


class systemCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$routeParams', 'socketService','toastr'];
    loading: boolean = false;
    id: string;
    model: any;
    toastrObj :any;
    genericServices: GenericServiceClass;
    constructor(
        public $scope,
        public $location,
        public $routeParams,
        public socketService: socketServiceFactory,
        public toastr
    ) {
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket,this.socketService.namespace)

        this.toastrObj = toastr;
        this.id = this.$routeParams.id;
        socketService.onSignedin(() => {
            this.loaddata();
        });

    }
    loaddata() {
        clearError(this.$scope);
        try {
            if (this.id) {
                this.loading = true;
                var query = "_id eq '" + this.id + "'";
                var items = this.genericServices.get(query, 1, null, null, null, 'assets');
                this.model = items[0];
                if (!this.model) {
                    var _err = { data: { error: { message: "asset with id " + this.id + " was not found!" } } };
                    // handleError(this.$scope, _err);
                    this.toastrObj.error(GetErrorMessage(_err));
                    return;
                }
                this.loading = false;
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            } else {
                var frequency = 30;
                // var owner = socketService.user().username;
                // var responsible = socketService.user().username;
                // var consultant = socketService.user().username;
                // var notification = socketService.user().username;
                // var support = socketService.user().username;
                var owner = '';
                var responsible = '';
                var consultant = '';
                var notification = '';
                var support = '';
                var nextrun = new Date();
                nextrun.setDate(nextrun.getDate() + frequency);
                this.loading = false;
                this.model = {};
                this.model.name = 'New system';
                this.model._type = 'system';
                this.model.reviewworkflows = [
                    {
                        name: 'Sletning review',
                        instruction: 'Handling i forbindelse med gennemgang af sletning',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Databehandler aftale review',
                        instruction: 'Handling i forbindelse med gennemgang af databehandler aftaler',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Log review',
                        instruction: 'Handling i forbindelse med gennemgang af logs',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Samtykke erklæring review',
                        instruction: 'Handling i forbindelse med gennemgang af sametykke erklæringer',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Medarbejder instruks',
                        instruction: 'Handling i forbindelse med gennemgang af medarbejder instrukser',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Brugerautoritation review',
                        instruction: 'Handling i forbindelse med gennemgang af brugerautoritationer',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Persondata politik review',
                        instruction: 'Handling i forbindelse med gennemgang af persondata poletiker',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Behandlingsoversigt review',
                        instruction: 'Handling i forbindelse med gennemgang af behandlingsoversigter',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Informationssikkerhedspolitik review',
                        instruction: 'Handling i forbindelse med gennemgang af Informationssikkerhedspolitiker',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                    {
                        name: 'Beredskabsplan review',
                        instruction: 'Handling i forbindelse med gennemgang af beredskabsplaner',
                        frequency: frequency,
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support,
                        nextrun: nextrun
                    },
                ];
                this.model.eventworkflows = [
                    {
                        name: 'Anmodning om sletning',
                        instruction: 'Handling ved en anmodning om sletning',
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support
                    },
                    {
                        name: 'Sikkerheds brist',
                        instruction: 'Handling ved en sikkerheds brist',
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support
                    },
                    {
                        name: 'Anmodning om indsigt',
                        instruction: 'Handling ved en anmodning om indsigt',
                        owner: owner,
                        responsible: responsible,
                        consultant: consultant,
                        notification: notification,
                        support: support
                    },
                ];
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) {
            // handleError(this.$scope, err);
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
    copyfield(data, field) {
        this.model.reviewworkflows.forEach((item) => {
            item[field] = data;
        });
        this.model.eventworkflows.forEach((item) => {
            item[field] = data;
        });
    }
    async update() {
        var model = JSON.parse(angular.toJson(this.model));
        this.loading = true;
        clearError(this.$scope);
        try {
           // var t0 = performance.now();
            if (model._id) {
                await this.genericServices.put(model, 'assets'); 
                this.$location.path("/systems");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
            else 
            {
                await this.genericServices.post(model, 'assets'); 
                this.$location.path("/systems");
                if (!this.$scope.$$phase) { this.$scope.$apply(); }
            }
        } catch (err) { 
            this.toastrObj.error(GetErrorMessage(err));
        }
    }
}
